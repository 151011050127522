* {
  margin: 0;
  padding: 0;
}

.App {
  padding: 200px;
  width: 50%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: center;
  box-sizing: border-box;
  font-family: 'Courier New', Courier, monospace;
}

.btn {
  padding: 0.5em 2.5em;
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: #191442;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  box-sizing: border-box;
  font-family: 'Courier New', Courier, monospace;

}

.btn.active {
  background-color: #191442;
  color: #fff;
  box-sizing: border-box;
  font-family: 'Courier New', Courier, monospace;

}

.modalOuter {
  width: 80%;
  height: 50%;
  position: absolute;
  background-color: #111010;
}

.modalBox {
  width: 30%;
  margin: 0 auto;
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-sizing: border-box;
  font-family: 'Courier New', Courier, monospace;

}

.heading,
.drag {
  font-weight: 600;
  color: #191442;
  box-sizing: border-box;
  font-family: 'Courier New', Courier, monospace;
}

.drag {
  margin-top: 1em;
  margin-bottom: 0.5em;
  cursor: pointer;
}

.drag .browse {
  display: inline-block;
  color: #033F8F;
}

.file-label {
  cursor: pointer;
}

.instruction,
.info {
  color: #6f6c78;
}

.heading {
  font-size: 25px;
}

.instruction {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  font-size: 14px;
  box-sizing: border-box;
  font-family: 'Courier New', Courier, monospace;
}

.drag {
  font-size: 18px;
}

.info {
  font-size: 12px;
}

.btn-container {
  display: flex;
  border: solid 0.1px;
  border-radius: 5px;
}

.uploadBox,
.signBox {
  padding: 2em;
  /* border: 2px dashed #7866e3; */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  font-family: 'Courier New', Courier, monospace;
}

/* Tab Container */

.Tabs {
  width: 100%;
  height: auto;
  min-height: 300px;
  border-radius: 2rem;
}

/* Tab Navigation */

ul.nav {
  width: 80%;
  margin: 0 auto 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid #d9d4f3; */
  border-radius: 0.5rem;
}

ul.nav li {
  width: 50%;
  padding: 0.5rem 2em;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 0.5em;
  color: #191442;
  font-size: 13px;
  font-weight: 600;
}

ul.nav li.active {
  background: #191442;
  color: #fff;
}

/* First and Second Tab Styles */

.upload-icon {
  font-size: 2em;
  color: #7866e3;
}

.fileIcon {
  display: none;
}

.filename {
  font-size: 12px;
}
