/* FILEPATH: /phishnet/src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

/* Reset default styles */
body, h1, h2, p {
  padding: 50;
  margin: 1cm
}

/* Set font and background color */
body {
  font-family: 'Courier New', Courier, monospace;
  background-color: #111010;
  color: #fff;
  font-size: 15px;
}

/* Add styles for header */
header {
  background-color: #111010;
  color: #fff;
  padding: 100px;
}

/* Add styles for main content */
main {
  margin: 20px;
}

/* Add styles for links */
a {
  color: #83bfff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Add styles for buttons */
button {
  border: 2px solid #033F8F;
  background-color: #fff;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  font-family: 'monospace', Courier, monospace;
  transition-duration: 0.2s;
}

button:hover {
  background-color: #033F8F;
  color: #fff;
}


#rcorners2 {
  border-radius: 25px;
  border: 2px solid #033F8F;
  padding: 20px;
  width: 900px;
  height: 150px;
  margin: auto;
  text-align: center;
  float: left;
}

.FirstTab{
  color: black;
}